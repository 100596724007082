.hero-social {
  height: 100vh;
  position: fixed;
  font-size: 25px;
  width: 50px;
  height: 50px;
  top: 40%;
  z-index: 5;
  transform: translate(-50%, -50%);
  div {
    background: #494db0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.224);
    a {
        color: #fff
    }
  }
  @include respond('nav-port'){
    display: none;
  }   
}


.ar .hero-social {
    right: 0%;
}

.en .hero-social {
    left: 3%;
}